<template>
    <div class="column" :class="[$mq]" :style="{ width: computedWidth }">
        <header>
            <span :class="['quantity', data.color]">{{ timers.length }}</span>
            <span class="text">{{ data.name }}</span>
        </header>
        <div class="content">
            <Timer @clickTimer="clickTimer(timer.id)" v-for="timer in timers" :visibilityType="visibilityType" :key="timer.id" :data="timer"></Timer>
        </div>
    </div>
</template>

<script>
import Timer from '@/components/domain/timerproduct/timer.vue'

export default {
    components: { Timer },
    props: {
        data: { type: Object },
        timers: { type: Array },
        visibilityType: { type: Number },
        countColumns: {type: Number}
    },
    data() {
        return {}
    },
    computed: {
        computedWidth() {
            return this.countColumns > 3 ? '30%' : '33%';
        }
    },
    methods: {
        clickTimer(id) {
            console.log('clickTimer', id)
            this.$emit('clickTimer', id)
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.column {
    flex: 0 0 auto;
    width: 33%; // Adjust this width as needed
    margin-right: 8px;
    display: inline-block;
    vertical-align: top;
    height: 100%;

    &:last-child {
        margin-right: 0;
    }

    header {
        @include display-flex();
        @include justify-content(center);
        @include align-items(center);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        text-align: left;
        width: 100%;
        text-align: center;
        height: 40px;
        cursor: pointer;
        padding: 5px;
        text-transform: uppercase;
        background-color: #ddd;

        .text {
            @include font-size(sm);
            @include text-ellipsis();
            font-family: $text;
            color: $neutro-s90;
            text-align: left;
            padding-left: 8px;
            width: 100%;
        }

        .quantity {
            background-color: $inactive;
            padding: 6px 8px;
            width: 30px;
            text-align: center;
            font-size: 18px;
            border-radius: 4px;
            font-family: Avenir-Bold;
            background-color: $color-error-500;
            color: #fff;

            &.ok {
                background-color: $done;
            }
            &.error {
                background-color: $color-error-500;
            }
        }
    }

    .content {
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4px;
        padding: 4px;
        grid-template-rows: max-content;
        overflow-y: auto;
    }
}
</style>
